@use 'abstacts/variables';
*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
html,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  color: variables.$carbon;
  background-color: variables.$offWhite;
}

// Overwriting Input Border

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: variables.$lightAccent !important;
}

// global style classes

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.h-full {
  height: 100%;
}

.flex-column {
  flex-direction: column;
}

.content-container {
  padding: 1rem;

  .skeleton {
    height: 100%;
    border-radius: 5px;
  }
}
