// Below are all the animations used throughout the project

// Gradient Move
// This moves the gradient to right and back
@keyframes move-gradient {
  from {
    background-position: 0%;
  }
  to {
    background-position: 100%;
  }
}
