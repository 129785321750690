@use "../../../scss/abstacts/variables";
@use "../../../scss/abstacts/animations";

.loader-view {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    to bottom right,
    variables.$accent,
    variables.$lightAccent
  );
  background-size: 200%;
  background-position: 0%;
  animation: move-gradient 1s ease 0s infinite alternate;

  img {
    max-width: 400px;
    width: 100%;
    object-fit: contain;
  }
}
